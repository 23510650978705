.footer {
    background-image: url('../../img/footer-bg.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.footer-mylogo{
    max-width: 150px;
}
.text-center-custom{
    text-align: center;
}
.text-center-custom h5{
    font-weight: 530;
}
.custom-text-muted {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    text-align: center;
    margin: 0;
}

.footer-social-icons{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.footer-social-icons i{
    color: #fff;
    margin-left: 20px;
    font-size: 30px;
    width: 50px;
    height: 50px;
    align-items: center;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 10px;
}
.footer-social-icons i:hover{
    cursor: pointer;
    color: black;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
}
.footer-text{
    margin-top: 20px;
}

@media only screen and (max-width:500px){
    .footer-social-icons{flex-direction: column;}
    .footer-social-icons i{font-size: 20px;width: 40px;height: 40px;margin-left: 10px;}
    .col-sm i{font-size: 30px;}
}