.contact {
  margin-top: 10rem;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding-top: 100px;
  padding-bottom: 100px;
}
.contact-title {
  font-weight: 800;
  font-size: 45px;
  margin-bottom: 50px;
}
.col-sm i{
  font-size: 40px;
  margin-bottom: 10px;
  /* text-shadow: 2px 3px 10px #cfcece; */
}
.contact-image{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  max-width: 85%;
}

.form-control {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.form-control:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff!important;
}
.form-control:focus::placeholder {
  color: #121212!important;
  opacity: 0.8;
}

.contact-button {
  font-weight: 700;
  padding: 10px;
  font-size: 18px;
  margin-top: 25px;
  margin-left: 8px;
}
.contact-button i {
  color:blue;
  margin-right: 10px;
}
.footer .container{
  padding: 40px;
}
.alert-mssg{
  font-size: 20px;
  font-weight: bold;
}
