.tilt-card{
	margin-top: 3rem;
	margin-right: auto;
	margin-left: auto;
}
.tilting-card-body {
	font-size: 25px;
	width:500px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: auto;
	padding: 1em;
	border-radius: 1em;
	box-shadow: 0px 1px 2px 0px rgba(0,255,255,0.7),
            1px 2px 4px 0px rgba(0,255,255,0.7),
            2px 4px 8px 0px rgba(0,255,255,0.7),
            2px 4px 16px 0px rgba(0,255,255,0.7);
	background: #222;
}
b{
	font-weight: 600;
	color: var(--red);
}

@media only screen and (max-width: 1000px){
    .tilting-card-body{width:500px}
}

@media only screen and (max-width: 540px){
    .tilting-card-body{width:400px}
}
@media only screen and (max-width: 443px){
    .tilting-card-body{width:300px}
}