.skills {
    margin-top: 10rem;
    margin-bottom: 10rem;
    position: relative;
}
.text-center {
    font-weight: 800;
    margin-bottom: 5rem;
}
.skill{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.skill span{
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
}