#portfolio{
  margin-top: 8rem;
}
.text-center {
  font-weight: 800;
  margin-bottom: 5rem;
}
.nav-tabs{
  /* border-bottom: 2px solid var(--red)!important; */
  border: none;
}
.nav-tabs button{
  border: 0.5px solid #ddd !important;
  color: #fff;
  width: 120px;
  border-radius: 10px;
  margin: 10px;
}
.nav-tabs .nav-link:hover{
  color: var(--red);
}
.nav-tabs .nav-link.active{
  color: #fff;
  font-weight: 600;
  background: rgb(194,5,177);
  background: linear-gradient(90deg, rgba(194,5,177,1) 0%, rgba(121,12,108,1) 45%, rgba(67,17,159,1) 100%);
}

.card-image {
  display: block;
  width: 100%;
  height: 270px;
  border-radius: 30px;
}

.image-overlay {
  position: relative;
  margin-top: 25px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  opacity: 0;
  transition: .5s ease;
  background: -webkit-linear-gradient(rgba(29, 38, 113, 0.8), rgba(195, 55, 100, 0.8));
  background: linear-gradient(rgba(29, 38, 113, 0.8), rgba(195, 55, 100, 0.8));
}

.image-overlay:hover .overlay {
  opacity: 1;
}

.text {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 60%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.down {
  width:100%;
  position: absolute;
  top: 70% !important;
}