.intro-background {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    height: 90vh;
    position: relative;
}

.intro-name {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
}

.intro-photo {
    height: 80vh;
}

.intro-first {
    font-size: 2.5rem;
    font-weight: 600;
}

.intro-second {
    font-size: 5rem;
    color: var(--red);
    font-weight: 800;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ffbb33;
}

.Typewriter__wrapper {
    font-size: 2rem;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #121212;
}

.Typewriter__cursor {
    font-size: 2rem;
}

.social-icons {
    margin-top: 3rem;
}

.social-icons i {
    margin: 20px;
    font-size: 30px;
    width: 50px;
    height: 50px;
    align-items: center;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 10px;
}

.social-icons i:hover {
    cursor: pointer;
    color: black;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
}

.bttn {
    width: 200px;
    border: 2px solid transparent;
    border-radius: 0;
    padding: 12px 28px;
    margin-top: 50px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    transition: all .3s;
    position: relative;
    color: #121212;
    background-color: #d4d3cf;
    z-index: 20;
    overflow: hidden;
}

.bttn:hover {
    color: #FFF;
    background-color: #8a8787;
    border: 2px solid #000000;
}

.blog-button {
    border: none;
    font-size: 16px;
    padding: 16px 55px 16px 25px;
}

.blog-button:hover {
    border: none;
    background-color: #524e4e;
	color: #fb8f3d;
}

.blog-button:hover > .blog-btn-text {
    color: white;
}

.blog-button:before {
    position: absolute;
    content: '\f37d';
    font-family: 'Font Awesome\ 5 Brands';
    top: 9px;
    right: 16px;
    font-size: 24px;
}

.blog-button:hover:before {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}

.blog-button:after {
    content: "";
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    right: 0;
    top: 0;
    height: 100%;
    width: 0;
    z-index: -1;
    transition: all .3s;
}

.blog-button:hover:after {
    width: 50px;
    height: 70px;
    right: -5px;
    top: -5px;
    -ms-transform: rotate(15deg);
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}

@media only screen and (max-width:900px){
    .social-icons i{color: #fff;}
}

@media only screen and (max-width:400px){
    .Typewriter__wrapper {font-size: 1.9rem;}
    .Typewriter__cursor {font-size: 1.9rem;}
}