.card-wrapper{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-bottom: 80px;
}
.card{
    border-radius: 70px;
    margin: 20px;
    position: relative;
    height: 500px;
    background: #121212;
    padding: 50px;
}
.card::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -10px;
    border-radius: inherit;
    background-image: linear-gradient(45deg, purple, orange);
}

.card-body>i{
    font-size: 68px;
    color: var(--red);
    text-align: center;
    margin-bottom: 50px;
}
.card-title{
    color: #fff;
    margin-bottom: 35px;
    font-weight: 500;
    letter-spacing: 1.2px;
}
.card-text{
    text-align: center;
    letter-spacing: 0.8px;
    line-height: 25px;
}

@media only screen and (max-width: 1000px) {
    .card-wrapper{width: 70%;}
}

@media only screen and (max-width: 770px) {
    .card-wrapper{width: 100%;}
    .card{border-radius: 50px;height:550px;}
}
