.profile {
    margin-top: 8rem;
    margin-bottom: 8rem;
    position: relative;
}

.text-center {
    font-weight: 800;
    margin-bottom: 3rem;
}

.profile-title {
    font-size: 1.8rem;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
}

.btn-link {
    text-decoration: none;
    position: relative;
}

.profile-red-underline::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 0;
    background-color: var(--red);
    left: 10px;
    transition: 0.5s;
    bottom: 3px;
}

.profile-red-underline:hover::after {
    width: 93%;
}

.active-underline::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 93%;
    background-color: var(--red);
    left: 10px;
    transition: 0.5s;
    bottom: 3px;
}

.collapse-item span{
    font-size: 22px;
    text-align: justify;
}

.collapse-item {
    position: relative;
    padding: 5px;
    /* animation: animateleft 0.4s; */
    animation: opac 1.5s
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

@keyframes opac {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@media only screen and (max-width: 1000px){
    .profile-title {font-size: 20px;}
}

@media only screen and (max-width: 600px){
    .collapse-item span{font-size:18px}
}